import React from 'react';
import { Helmet } from 'react-helmet';
import DefaultLayout from '../containers/default';

const Careers = () => (
  <DefaultLayout noLogin>
    <Helmet>
      <title>Careers at all.health</title>
      <meta
        httpEquiv="refresh"
        content="0;url=https://jobs.lever.co/all.health"
      />
    </Helmet>
  </DefaultLayout>
);

export default Careers;
